export const VERSAO_SISTEMA = '1.43.8';

export const CONSUMIDOR_ID = '82dc607a-ef6e-4341-83d9-6f17482d7614';

export const CREDENCIADORA_TEF_ID = '47ffeccc-f3ee-4c4b-b509-d032efa881f2';

export const MASTER_ID = 'e69a05bd-d2a4-4577-b7dd-37ecd2d5b1d5';

export const ITEM_CODIGO_EM_CADASTRO_ID = '65c54029-22b3-49a8-904d-f4e2b4bdb050';

export const ITEM_EM_CADASTRO_ID = '40d55367-7305-4321-855c-be98dda1e616';

export const Tropics = {
	LIBERACAO: 'LIBERACAO',
	LIBERADO_OU_NAO_LIBERADO: 'LIBERADO_OU_NAO_LIBERADO',
	LIBERACAO_EXCLUIDA: 'LIBERACAO_EXCLUIDA'
};
