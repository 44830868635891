import axios from 'axios';
import { Notify } from 'quasar';
import { getUrlApi, getUrlApiLiberacoes, getUrlApiLiberacoesAndPath, getUrlSocket, trataMensagemErrorTry } from 'src/util/AppUtil';
import { capturarErroGraphql } from 'src/util/GraphqlUtil';
import io from 'socket.io-client';

export default class ApiGraphql {
	static async post(url: string, data: any, headers?: any) {
		const urlApi = await getUrlApi();
		try {
			const response = await axios.post(`${urlApi}/${url}`, data, headers);
			if (response) {
				capturarErroGraphql(response.data);
			}
			if (response && response.data) return response.data;
		} catch (error: any) {
			Notify.create({
				message: `${trataMensagemErrorTry(error)}`,
				classes: 'notify-class-erro',
				position: 'top',
				timeout: 6000,
				multiLine: true,
				icon: 'fas fa-thumbs-down',
				actions: [
					{
						label: 'Fechar',
						handler: () => {}
					}
				]
			});
		}
	}

	static async postRetornoCompleto(url: string, data: any, headers?: any) {
		const urlApi = await getUrlApi();
		try {
			const response = await axios.post(`${urlApi}/${url}`, data, headers);
			if (response) {
				capturarErroGraphql(response.data);
			}
			if (response) return response;
		} catch (error: any) {
			Notify.create({
				message: `${trataMensagemErrorTry(error)}`,
				classes: 'notify-class-erro',
				position: 'top',
				timeout: 6000,
				multiLine: true,
				icon: 'fas fa-thumbs-down',
				actions: [
					{
						label: 'Fechar',
						handler: () => {}
					}
				]
			});
		}
	}
}

export class ApiLiberacoes {
	static async post(url: string, data: any, headers?: any) {
		let urlApi: any = '';
		const urlApiGraphql = await getUrlApi();
		const urlApiLiberacoes = await getUrlApiLiberacoes();
		if (!urlApiLiberacoes) {
			urlApi = urlApiGraphql;
		} else {
			urlApi = urlApiLiberacoes;
		}
		try {
			const response = await axios.post(`${urlApi}/${url}`, data, headers);
			if (response) {
				capturarErroGraphql(response.data);
			}
			if (response && response.data) return response.data;
		} catch (error: any) {
			Notify.create({
				message: `${trataMensagemErrorTry(error)}`,
				classes: 'notify-class-erro',
				position: 'top',
				timeout: 6000,
				multiLine: true,
				icon: 'fas fa-thumbs-down',
				actions: [
					{
						label: 'Fechar',
						handler: () => {}
					}
				]
			});
		}
	}
}
export class ApiSocket {
	static async socket() {
		let urlApi = 'http//localhost:4000';
		let path = '/socket.io';
		const urlApiGraphql = await getUrlApi();
		const urlApiLiberacoes = await getUrlApiLiberacoes();
		const urlSocket = await getUrlSocket();
		if (!urlApiLiberacoes && urlApiGraphql) {
			const retorno = getUrlApiLiberacoesAndPath(urlApiGraphql);
			urlApi = retorno.url;
			path = retorno.path;
		} else if (urlApiLiberacoes) {
			const retorno = getUrlApiLiberacoesAndPath(urlApiLiberacoes);
			urlApi = retorno.url;
			path = retorno.path;
		}
		if (urlSocket) {
			const retorno = getUrlApiLiberacoesAndPath(urlSocket);
			urlApi = retorno.url;
			path = retorno.path;
		}
		return io(urlApi, {
			path,
			autoConnect: false,
			transports: ['websocket', 'polling']
		});
	}
}
