import { MutationTree } from 'vuex';
import Storage from '../../util/LocalStorageUtil';
import { StateInterfaceInicial } from './state';
const storage = new Storage();

const mutation: MutationTree<StateInterfaceInicial> = {
	setTheme(state, payload) {
		state.theme = payload;
	},

	setModo(state, payload) {
		state.modo = payload;
	},

	setOperador(state, payload) {
		state.operador = payload;
		storage.set('operador', JSON.parse(JSON.stringify(payload)));
	},

	setMensagemFinalizar(state, payload) {
		state.mensagemFinalizar = payload;
		storage.set('setMensagemFinalizar', JSON.parse(JSON.stringify(payload)));
	},

	setGrupoEmbalagemRetornavel(state, payload) {
		state.grupoEmbalagemRetornavel = payload;
		storage.set('grupoEmbalagemRetornavel', JSON.parse(JSON.stringify(payload)));
	},

	setOperacao(state, payload) {
		state.operacao = payload;
		storage.set('operacao', JSON.parse(JSON.stringify(payload)));
	},

	setOperacoes(state, payload) {
		state.operacoes = payload;
		storage.set('operacoes', JSON.parse(JSON.stringify(payload)));
	},
	setCorBorda(state, payload) {
		state.corBorda = payload;
		storage.set('corBorda', JSON.parse(JSON.stringify(payload)));
	},
	setCnpjTef(state, payload) {
		state.cnpjTef = payload;
		storage.set('cnpjTef', JSON.parse(JSON.stringify(payload)));
	},
	setOperacaoCodigo(state, payload) {
		state.operacaoCodigo = payload;
		storage.set('operacaoCodigo', JSON.parse(JSON.stringify(payload)));
	},

	setImprimirRecibo(state, payload) {
		state.imprimirRecibo = payload;
		storage.set('imprimirRecibo', JSON.parse(JSON.stringify(payload)));
	},

	setQuantidadeVias(state, payload) {
		state.quantidadeVias = payload;
		storage.set('quantidadeVias', JSON.parse(JSON.stringify(payload)));
	},
	setQuantidadeViasPadrao(state, payload) {
		state.quantidadeViasPadrao = payload;
		storage.set('quantidadeViasPadrao', JSON.parse(JSON.stringify(payload)));
	},

	setPdv(state, payload) {
		state.pdv = payload;
		storage.set('pdv', JSON.parse(JSON.stringify(payload)));
	},

	setToken(state, payload) {
		state.token = payload;
		storage.set('token', JSON.parse(JSON.stringify(payload)));
	},

	setVendaMedicamento(state, payload) {
		state.vendaMedicamento = payload;
		storage.set('vendaMedicamento', JSON.parse(JSON.stringify(payload)));
	},

	setIdentificacao(state, payload) {
		state.identificacao = payload;
		storage.set('NFCeIdentificacao', JSON.parse(JSON.stringify(payload)));
	},

	setCliente(state, payload) {
		state.cliente = payload;
		storage.set('cliente', JSON.parse(JSON.stringify(payload)));
	},

	setVendedor(state, payload) {
		state.vendedor = payload;
		storage.set('vendedor', JSON.parse(JSON.stringify(payload)));
	},

	setTipos(state, payload) {
		state.tabelas = payload;
		storage.set('tipos', JSON.parse(JSON.stringify(payload)));
	},

	setFrenteLoja(state, payload) {
		state.frenteLoja = payload;
		storage.set('frenteLoja', JSON.parse(JSON.stringify(payload)));
	},

	setSistemaAtualizado(state, payload) {
		state.sistemaAtualizado = payload;
		storage.set('sistemaAtualizado', JSON.parse(JSON.stringify(payload)));
	},

	setTabelas(state, payload) {
		state.tabelas = payload;
		storage.set('tabelas', JSON.parse(JSON.stringify(payload)));
	},

	setItemCodigoEmbalagensConsultadas(state, payload) {
		state.itemCodigoEmbalagensConsultadas = JSON.parse(JSON.stringify(payload));
		storage.set('itemCodigoEmbalagensConsultadas', JSON.parse(JSON.stringify(payload)));
	},

	changeTabela(state, payload) {
		state.tabela = payload;
		storage.set('tabela', JSON.parse(JSON.stringify(payload)));
	},

	setSolicitarVendedor(state, payload) {
		state.solicitarVendedor = payload;
		storage.set('solicitarVendedor', JSON.parse(JSON.stringify(payload)));
	},

	setSolicitarVendedorESenha(state, payload) {
		state.solicitarVendedorESenha = payload;
		storage.set('solicitarVendedorESenha', JSON.parse(JSON.stringify(payload)));
	},

	setPorcentagemMaxAlterarValUn(state, payload) {
		state.porcentagemMaxAlterarValUn = payload;
		storage.set('porcentagemMaxAlterarValUn', JSON.parse(JSON.stringify(payload)));
	},

	setPermiteAlterarValorUn(state, payload) {
		state.permiteAlterarValorUn = payload;
		storage.set('permiteAlterarValorUn', JSON.parse(JSON.stringify(payload)));
	},

	setImpressora(state, payload) {
		state.impressora = payload;
		storage.set('impressora', JSON.parse(JSON.stringify(payload)));
	},

	changeImpressora(state, payload) {
		state.impressora = JSON.parse(JSON.stringify(payload));
	},

	changeShowBuscaPessoa(state, payload) {
		state.showBuscaPessoa = JSON.parse(JSON.stringify(payload));
	},

	changeFullscreen(state, payload) {
		state.fullScreen = JSON.parse(JSON.stringify(payload));
	},

	changeModoConsulta(state, payload) {
		state.modoConsulta = JSON.parse(JSON.stringify(payload));
	},

	changeDark(state, payload) {
		state.dark = JSON.parse(JSON.stringify(payload));
	},

	changeServico(state, payload) {
		state.servico = JSON.parse(JSON.stringify(payload));
	},

	changePrimeiroItemVenda(state, payload) {
		state.primeiroItemVenda = JSON.parse(JSON.stringify(payload));
	},

	changeOperacaoAberta(state, payload) {
		state.operacaoAberta = JSON.parse(JSON.stringify(payload));
	},

	changeCodigoUltimoPedido(state, payload) {
		state.codigoUltimoPedido = JSON.parse(JSON.stringify(payload));
	},

	changeTrocoUltimoPedido(state, payload) {
		state.trocoUltimoPedido = JSON.parse(JSON.stringify(payload));
	},

	changeQuantidadeItemFixa(state, payload) {
		state.quantidadeItemFixa = JSON.parse(JSON.stringify(payload));
	},

	changeIsFiltroBuscaItemCodigo(state, payload) {
		state.isFiltroBuscaItemCodigo = JSON.parse(JSON.stringify(payload));
	},
	changeUrlApiGraphql(state, payload) {
		state.urlApiGraphql = JSON.parse(JSON.stringify(payload));
	},
	changeUrlImpressao(state, payload) {
		state.urlImpressao = JSON.parse(JSON.stringify(payload));
	},
	changeEditandoLinha(state, payload: boolean) {
		state.editandoLinha = payload;
	},
	changeEstoquePrincipal(state, payload) {
		state.estoquePrincipal = JSON.parse(JSON.stringify(payload));
		storage.set('estoquePrincipal', JSON.parse(JSON.stringify(payload)));
	},
	changeEstadoBotaoSalvar(state, payload: boolean) {
		state.estadoBotaoSalvar = payload;
	}
};

export default mutation;
